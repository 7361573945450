
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';

    import {formHelper} from '@/helpers';
    import {IUser} from '@/types';

    const accountNamespace = namespace('account');

    @Component
    export default class ViewContact extends Vue {
        public contactFormValid: boolean = false;
        public loading: boolean = false;

        public snackbar: boolean = false;
        public snackbarColor: string = '';
        public snackbarText: string = '';

        public name: string|null = null;
        public email: string|null = null;
        public subject: string|null = null;
        public message: string|null = null;

        public emailRules = formHelper.getEmailsRules();
        public nameRules = formHelper.getNameRules();
        public contactSubjectRules = formHelper.getContactSubjectRules();
        public contactMessageRules = formHelper.getContactMessageRules();

        @accountNamespace.Getter('loggedUser')
        public user!: IUser|null;

        public reasons = [
            {value: 'prospect', text: 'Je suis vétérinaire et souhaite obtenir des renseignements'},
            {value: 'ask-veterinarian', text: 'Je veux connaitre le statut de mon rendez-vous'},
            {value: 'suggestion', text: 'Je veux faire une suggestion'},
            {value: 'update-profile', text: 'Je veux modifier mes informations personnelles'},
            {value: 'technical', text: 'Je signale un dysfonctionnement'},
            {value: 'other', text: 'Je souhaite poser une autre question'},
        ];

        public submitContact() {
            this.loading = true;

            this
                .$recaptchaLoaded()
                .then(() => {
                    this
                        .$recaptcha('contact')
                        .then((token: string) => {
                            this.$api.contact
                                .sendMessage({
                                    name: this.name as string,
                                    email: this.email as string,
                                    subject: this.subject as string,
                                    message: this.message as string,
                                    tags: ['client'],
                                    type: 'contact',
                                    token,
                                })
                                .then(() => {
                                    this.resetForm();

                                    this.snackbarColor = 'success';
                                    this.snackbarText = 'Votre message a bien été envoyé. Nous reviendrons vers vous dans les plus brefs délais !';
                                    this.snackbar = true;
                                })
                                .catch(() => {
                                    this.snackbarColor = 'error';
                                    this.snackbarText = 'Une erreur est survenue pendant l\'envoi de votre message. Veuillez réessayer ultérieurement.';
                                    this.snackbar = true;
                                })
                                .finally(() => {
                                    this.loading = false;
                                    this.resetForm();

                                    this.snackbarColor = 'success';
                                    this.snackbarText = 'Votre message a bien été envoyé. Nous reviendrons vers vous dans les plus brefs délais !';
                                    this.snackbar = true;
                                })
                            ;
                        })
                    ;
                })
            ;
        }

        private resetForm() {
            this.message = null;
            this.subject = null;

            (this.$refs.contactForm as HTMLFormElement).resetValidation();
        }

        private initForm() {
            if (!this.user) {
                return;
            }

            if (this.user.client) {
                this.name = `${this.user.client.first_name} ${this.user.client.last_name}`;
            }

            if (this.user.account) {
                this.email = this.user.account.email;
            }
        }

        private mounted() {
            this.initForm();
        }
    }
